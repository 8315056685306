import router from "@/router/index";
import https from "@/utils/api";
import { CACHE_KEY } from "@/utils/constant";
import { MessageBox } from "element-ui";

// 登录后的整个信息
const LoginInfo = JSON.parse(localStorage.getItem(CACHE_KEY.AI_LOGIN_INFO));

let AuthorizationObj = JSON.parse(localStorage.getItem(CACHE_KEY.AI_HA));

// 可选环境列表
const currentEnv =
  window.localStorage.getItem(CACHE_KEY.AI_ENV) ||
  (LoginInfo?.wxcloud&&LoginInfo?.wxcloud[0]?.miniprogram_envId[0]) ||
  "";

export default {
  namespaced: true,
  state: {
    // 登录信息
    LoginInfo,
    AuthorizationObj,
    // 当前云环境
    env: currentEnv,
  },
  mutations: {
    setLoginInfo(state, data) {
      state.LoginInfo = data;
      localStorage.setItem(CACHE_KEY.AI_LOGIN_INFO, JSON.stringify(data));
    },
    setAuthorizationObj(state, data) {
      state.AuthorizationObj = data;
      localStorage.setItem(CACHE_KEY.AI_HA, JSON.stringify(data));
    },
    setEnv(state, env) {
      state.env = env;
      localStorage.setItem(CACHE_KEY.AI_ENV, env);
    },
  },
  actions: {
    // 登录
    Login: async ({ commit, state }, { code }) => {
      if (!code) return;
      try {
        const res = await https.GetLoginByCodeApi(code);
        if (res.return_code !== 0) {
          return MessageBox.alert("授权登录失败");
        }
        commit("setLoginInfo", res.datas);
        commit("setAuthorizationObj", res.datas.headersauthorization);
        if (!state.env) {
          commit("setEnv", res?.datas?.wxcloud[0]?.miniprogram_envId[0] || "");
        }
        location.href =
          location.origin + location.pathname + "#/order/orderList";
        // router.replace({ name: "orderList" });
      } catch (e) {
        MessageBox.alert("授权登录失败").then(() => {
          location.href = location.origin;
        });
        console.error("e", e);
      }
    },
    // 登出
    Logout: ({ commit }) => {
      //  清除缓存
      commit("setAuthorizationObj", "");
      commit("setLoginInfo", "");
      commit("setWxcode", "", { root: true });
      commit("setEnv", "");

      // 跳转
      router.push({ name: "scanLogin" });
      // location.href = location.origin + location.pathname
    },
  },
  getters: {
    // 用户信息
    UserInfo: (state) => {
      const LoginInfo = state.LoginInfo;
      return {
        nickName: LoginInfo?.nickname, // 昵称
        openid: LoginInfo?.openid, // openid
        headimgurl: LoginInfo?.headimgurl, // 头像
        sex: LoginInfo?.sex, // 性别
        unionid: LoginInfo?.unionid,
      };
    },
    // 登录的Code
    LoginCode: (state) => {
      return state.LoginInfo?.headersauthorization?.signature || "";
    },
    // 请求的头
    apiHeaders: (state) => {
      let aObj = state.AuthorizationObj;
      if (!state.AuthorizationObj?.signature) {
        aObj = state.LoginInfo?.headersauthorization;
      }
      return {
        authorization: aObj?.authorization || "",
        signature: aObj?.signature || "",
        "X-Date": (aObj && aObj["x-date"]) || "",
        loginCode: state.LoginInfo?.headersauthorization?.signature || "",
      };
    },
    // 云环境列表
    envList: (state) => {
      return state.LoginInfo?.wxcloud&&state.LoginInfo?.wxcloud[0]?.miniprogram_envId || [];
    },
    // 应用AppNo
    AppNo: (state) => {
      return state.LoginInfo?.wxcloud&&state.LoginInfo?.wxcloud[0]?.AppNo || "";
    },
    // 应用appID
    AppId: (state) => {
      return state.LoginInfo?.wxcloud&&state.LoginInfo?.wxcloud[0]?.miniprogram_AppID || "";
    },
    // 系统使用有限期
    softwareExpirationTime: (state) => {
      return state.LoginInfo?.softwareExpirationTime?.split(" ")[0] || "";
    },
  },
};
