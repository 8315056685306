import { MessageBox } from "element-ui";
import store from "@/store/index"
let timeId;

export default function authoriza() {
  clearTimeout(timeId);
  timeId = setTimeout(() => {
    MessageBox.alert("认证过期，请重新登录").then(() => {
      store.dispatch({
        type: "auth/Logout",
      });
    });
  }, 300);
}
