import Vue from "vue";
import httpRequest from "./request";
import authoriza from "./authoriza";
import { sleep } from "./utils.js";
import { MessageBox } from "element-ui";
import store from "@/store/index";

let locked = true;
let isRefreshAuth = false,
  qCount = 0;

const cloud = async (params, name = "adminCloudFun", loading = true) => {
  qCount++;
  if (locked) {
    await sleep(1500);
  }
  //  业务的云函数调用
  let $loading;
  if (loading) $loading = Vue.prototype.$loading();
  return new Promise((resolve, reject) => {
    httpRequest({
      url: "mtcloud-web-router",
      method: "post",
      headers: {
        ...store.getters["auth/apiHeaders"],
      },
      withCredentials: false,
      data: {
        name: name, // 云函数名
        postbody: params, // 传入函数的参数实体
        appid: store.getters["auth/AppId"], // 小程序appid
        envId: store.state.auth.env || "", // 云环境ID
        appNo: store.getters["auth/AppNo"], // app编号
      },
    })
      .then((res) => {
        if (locked) locked = false;
        if (res.return_code == 0) {
          resolve(JSON.parse(res.datas));
        } else if (res.return_code == 100006) {
          isRefreshAuth = true;
        } else {
          MessageBox.alert("未知错误，请联系管理员");
        }
        loading && $loading.close();
      })
      .catch((err) => {
        if (locked) locked = false;
        reject(err);
        loading && $loading.close();
      })
      .then(() => {
        qCount--;
        if (isRefreshAuth && qCount === 0) {
          isRefreshAuth = false;
          authoriza();
        }
      });
  });
};

const readFile = (reader) => {
  return new Promise((resolve, reject) => {
    reader.onload = (e) => {
      resolve(e);
    };
  });
};

export default {
  cloud, // 云函数专用调用方法
  // 通过扫码返回的Code 换取 token
  GetLoginByCodeApi: async (code) => {
    return httpRequest.post("mtcloud-web-login", {
      code,
    });
  },

  // 自定义交易组件-添加商品 参数参考微信文档-自定义组件
  businessAddGoods: (options) => {
    return httpRequest.post("weixin_api", {
      data: options,
      path: "/shop/spu/add?access_token=",
    });
  },

  // 自定义交易组件-删除商品 参数参考微信文档-自定义组件
  businessDelGoods: (options) => {
    return httpRequest.post("weixin_api", {
      data: options,
      path: "/shop/spu/del?access_token=",
    });
  },

  /**
   * 转换云存储图片路径
   * fileList=[{fileid:"cloud://release-101-wi2uq.7265-release-101-wi2uq-1300494479/img/banner1.png"}]
   */
  getImagePath: async (fileList = []) => {
    try {
      const rs = await cloud(
        {
          opType: "batchDownLoadFile",
          file_list: fileList,
        },
        "mtcloudOperateFile",
        false
      );
      console.log(rs);
      if (rs.file_list) return rs.file_list;
      return [];
    } catch (e) {
      return [];
    }
  },

  uploadImage: async (dir, file) => {
    if (!file) return;
    if (file.size > 1024 * 1024 * 3) {
      //  最大3m
      MessageBox.alert("图片超过限制");
      return;
    }
    try {
      const path =
        dir + "/" + new Date().getTime() + "." + file.type.split("/")[1];
      console.log(path);
      let reader = new FileReader();
      reader.readAsDataURL(file);
      const data = await readFile(reader);
      const res = await cloud({
        action: "upload",
        path: path,
        base64: data.target.result,
      });
      console.log(res);
      return res.data.fileID;
    } catch (e) {
      MessageBox.alert("图片上传失败");
      return null;
    }
  },

  /**
   * 删除图片
   * 参数是数组
   */
  delImage: async (fileList) => {
    const rs = await cloud(
      {
        opType: "batchDeleteFile",
        fileid_list: fileList,
      },
      "mtcloudOperateFile"
    );
    console.log(rs);
  },

  uploadImage2: async (dir, file) => {
    if (!file) return;
    const path =
      dir + "/" + new Date().getTime() + "." + file.type.split("/")[1];
    console.log(path);
    const rs = await cloud(
      {
        opType: "uploadFile",
        path: path,
      },
      "mtcloudOperateFile"
    );
    console.log(rs);
    const res = await httpRequest({
      url: rs.url,
      method: "POST",
      headers: {
        "content-type": "multipart/form-data",
      },
      data: {
        key: path,
        Signature: rs.authorization,
        "x-cos-security-token": rs.token,
        "x-cos-meta-fileid": rs["cos_file_id"],
        file: file,
      },
    });
    console.log(res);
  },

  wxApplication: async (params) => {
    return new Promise((resolve, reject) => {
      let $loading = Vue.prototype.$loading();
      httpRequest
        .post("mtcloud-web-wxApplication", {
          AppNo: store.state.AppNo, // app编号
          miniprogram_AppSecret: params.appSecret, // 小程序秘钥
          miniprogram_Name: params.appName, // 小程序名称
          unionid: store.state.auth.UserInfo.unionid,
        })
        .then((res) => {
          if (res.return_code == 0) {
            resolve(res.datas);
          } else {
            MessageBox.alert("未知错误，请联系管理员");
          }
          $loading.close();
        })
        .catch((err) => {
          MessageBox.alert("未知错误，请联系管理员");
          reject(err);
          $loading.close();
        });
    });
  },

  feedback: async (params) => {
    return new Promise((resolve, reject) => {
      let $loading = Vue.prototype.$loading();
      httpRequest
        .post("mtcloud-web-feedback", {
          feedback: {
            nickname: params.nickname,
            phone: params.phone,
            describe: params.describe,
          },
        })
        .then((res) => {
          if (res.return_code == 0) {
            resolve(res.datas);
          } else {
            MessageBox.alert("未知错误，请联系管理员");
          }
          $loading.close();
        })
        .catch((err) => {
          MessageBox.alert("未知错误，请联系管理员");
          reject(err);
          $loading.close();
        });
    });
  },
};
