<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import {GetQueryString} from "@/utils/utils"
export default {
  created() {
    const query = GetQueryString();
    const LoginCode = query?.code;
    if (!LoginCode) return;
    this.$store.dispatch({
      type: "auth/Login",
      code: LoginCode,
    });
  },
};
</script>

<style lang="scss">
@import "~element-ui/lib/theme-chalk/index.css";
@import "~@/assets/fonts/iconfont.css";
@import "~@/assets/styles/common.scss";
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow: hidden;
  color: #2c3e50;
}
</style>
