import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";

Vue.use(VueRouter);

const routes = [
  // 登录页
  {
    path: "/scanLogin",
    name: "scanLogin",
    component: () => import("@/views/scanLogin/index"),
  },
  {
    path: "/",
    name: "index",
    component: () => import("../layout/index.vue"),
    children: [
      {
        path: "order/orderList",
        name: "orderList",
        meta: { group: "order" },
        component: () => import("../views/order/orderList.vue"),
      },
      {
        path: "goods/goodManage",
        name: "goodManage",
        meta: { group: "goods" },
        component: () => import("../views/goods/goodManage.vue"),
      },
      {
        path: "goods/goodGroup",
        name: "goodGroup",
        meta: { group: "goods" },
        component: () => import("../views/goods/goodGroup.vue"),
      },
      {
        path: "goods/groupDetail",
        name: "groupDetail",
        meta: { group: "goods" },
        component: () => import("../views/goods/groupDetail.vue"),
      },
      {
        path: "goods/addGood",
        name: "addGood",
        meta: { group: "goods" },
        component: () => import("../views/goods/addGood.vue"),
      },
      {
        path: "goods/editGood",
        name: "editGood",
        meta: { group: "goods" },
        component: () => import("../views/goods/editGood.vue"),
      },
      {
        path: "customer/customerManager",
        name: "customerManager",
        meta: { group: "customer" },
        component: () => import("../views/customer/customerManager.vue"),
      },
      {
        path: "setting/brandInfo",
        name: "brandInfo",
        meta: { group: "setting" },
        component: () => import("../views/setting/brandInfo.vue"),
      },
      {
        path: "setting/paySetting",
        name: "paySetting",
        meta: { group: "setting" },
        component: () => import("../views/setting/paySetting.vue"),
      },
      {
        path: "setting/feedback",
        name: "feedback",
        meta: { group: "setting" },
        component: () => import("../views/setting/feedback.vue"),
      },
      {
        path: "dynamic/dynamicManager",
        name: "dynamicManager",
        meta: { group: "dynamic" },
        component: () => import("../views/dynamic/dynamicManager.vue"),
      },
      {
        path: "dynamic/releaseDynamics",
        name: "releaseDynamics",
        meta: { group: "dynamic" },
        component: () => import("../views/dynamic/releaseDynamics.vue"),
      },
      {
        path: "dynamic/editDynamics",
        name: "editDynamics",
        meta: { group: "dynamic" },
        component: () => import("../views/dynamic/editDynamics.vue"),
      },
      {
        path: "dynamic/bannerSetting",
        name: "bannerSetting",
        meta: { group: "dynamic" },
        component: () => import("../views/dynamic/bannerSetting.vue"),
      },
      {
        path: "dynamic/brandIntroduction",
        name: "brandIntroduction",
        meta: { group: "dynamic" },
        component: () => import("../views/dynamic/brandIntroduction.vue"),
      },
      {
        path: "dynamic/productIntroduction",
        name: "productIntroduction",
        meta: { group: "dynamic", title: "产品介绍" },
        component: () => import("../views/dynamic/productIntroduction.vue"),
      },
      {
        path: "*",
        redirect: { name: "orderList" },
      }
    ],
  },
  // 重定向
  {
    path: "*",
    redirect: { name: "orderList" },
  },
];

const router = new VueRouter({
  // mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (!store.getters["auth/LoginCode"] && to.name !== "scanLogin") {
    return next({ name: "scanLogin", query: { ...from.query } });
  }
  if(to.name === "scanLogin" && !!store.getters["auth/LoginCode"]){
    return next({name:'orderList'});
  }
  return next();
});
export default router;
