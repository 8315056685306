// 常量目录

// 快递列表
export const DELIVERY_LISTS = [
  "圆通速递",
  "中通快递",
  "顺丰速运",
  "申通快递",
  "安能物流",
  "百世快递",
  "德邦快递",
  "EMS",
  "京东快递",
  "承诺达特快",
  "品骏快递",
  "优速快递",
  "韵达快递",
];

// 缓存的常量

export const CACHE_KEY = {
  AI_WXCODE: "AI_WXCODE",
  AI_ENV: "AI_ENV",
  AI_LOGIN_INFO: "AI_LOGIN_INFO",
  AI_HA: "AI_HA",
};
