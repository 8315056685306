import { isDev, ENVPATH } from "@/appConfig";

import axios from "axios";

let config = {
  baseURL: (isDev ? "/" : `${process.env.VUE_APP_API_BASE_URL}/`) + ENVPATH,
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // 添加请求头
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function(response) {
    if(typeof response.data === 'string'){
      return JSON.parse(response.data);
    }
    return response.data;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default _axios;
