import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import https from '@/utils/api.js';

import ElementUI from 'element-ui';

import filters from '@/utils/filters.js';


Object.keys(filters).forEach(k=>{
  console.log(k);
  Vue.filter(k,filters[k])
})

Vue.use(ElementUI,{ size: 'small' });

Vue.config.productionTip = false;

Vue.prototype.$https = https;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
