function getRegExp(target) {
  var flat = "";
  if (target.global) flat += "g";
  if (target.ignoreCase) flat += "i";
  if (target.multiline) flat += "m";
  return target;
}

export const Img_URL = (file) => {
  const _URL = window.URL || window.webkitURL;
  return _URL.createObjectURL(file);
};

export const clone = (target, visiteds) => {
  var Type = Object.prototype.toString.call(target).slice(8, -1);
  var copy = Type == "Array" ? [] : {};
  visiteds = visiteds || []; //处理环形数据，防止无限循环
  switch (Type) {
    case "Date":
      copy = new Date(target.getTime());
      break;
    case "RegExp":
      copy = new RegExp(target.source, getRegExp(target));
      break;
    case "Array":
    case "Object":
      var index = visiteds.indexOf(target);
      if (index > -1) {
        copy = visiteds[index];
      } else {
        visiteds.push(target);
        for (var key in target) {
          copy[key] = clone(target[key], visiteds);
        }
      }
      break;
    default:
      copy = target;
      break;
  }
  return copy;
};

export const sleep = async (time) => {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, time);
  }).catch((err) => reject(err));
};

export const formatDate = (val, fmt = "yyyy-MM-dd hh:mm") => {
  if (!val) {
    return undefined;
  }
  const date = new Date(val);
  let o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
    "q+": Math.floor((date.getMonth() + 3) / 3),
  };

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }

  Object.keys(o).forEach((k) => {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  });

  return fmt;
};

export const GetQueryString = function() {
  const url = location.href;
  if (url.indexOf("?") != -1) {
    var args = new Object(); //声明一个空对象

    //获取URL中全部参数列表数据
    var query = "&" + url.split("?")[1];

    if (query.indexOf("#") != -1) {
      query = query.split("#")[0];
    }

    var pairs = query.split("&"); // 以 & 符分开成数组
    for (var i = 0; i < pairs.length; i++) {
      var pos = pairs[i].indexOf("="); // 查找 "name=value" 对
      if (pos == -1) continue; // 若不成对，则跳出循环继续下一对
      var argname = pairs[i].substring(0, pos); // 取参数名
      var value = pairs[i].substring(pos + 1); // 取参数值
      value = decodeURIComponent(value); // 若需要，则解码
      args[argname] = value; // 存成对象的一个属性
    }
    return args;
  } else {
    return null;
  }
};

export const arrayBufferToBase64 = function(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  console.log(bytes);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export default {
  sleep,
  clone,
  Img_URL,
  formatDate,
};
