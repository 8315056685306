export default {
    formatDate(val, fmt="yyyy-MM-dd hh:mm"){
        if(!val) {
            return undefined;
        }
        const date = new Date(val);
        let o = {
            "M+": date.getMonth()+1,
            "d+": date.getDate(),
            "h+": date.getHours(),
            "m+": date.getMinutes(),
            "s+": date.getSeconds(),
            "q+": Math.floor((date.getMonth()+3)/3)
        };

        if(/(y+)/.test(fmt)){
            fmt = fmt.replace(
                RegExp.$1,
                (date.getFullYear()+'').substr(4-RegExp.$1.length)
            );
        }

        Object.keys(o).forEach(k=>{
            if(new RegExp("("+k+")").test(fmt)){
                fmt = fmt.replace(
                    RegExp.$1,
                    RegExp.$1.length === 1? o[k] : ('00'+o[k]).substr((''+o[k]).length)
                );
            }
        });

        return fmt;
    },
    ellipsis(val,len=20){
        if(val.length>len){
            return val.substring(0,len)+'...';
        }
        return val
    }
}