import Vue from "vue";
import Vuex from "vuex";
import https from "@/utils/api.js";
import { arrayBufferToBase64 } from "@/utils/utils";
import auth from "./modules/auth";
import { CACHE_KEY } from "@/utils/constant";

Vue.use(Vuex);


export default new Vuex.Store({
  state: {
    // 头部的小程序码
    wxcode: localStorage.getItem(CACHE_KEY.AI_WXCODE) || "",
  },
  mutations: {
    setWxcode(state, wxcode) {
      state.wxcode = wxcode;
      localStorage.setItem(CACHE_KEY.AI_WXCODE, wxcode);
    },
  },
  actions: {
    // 获取小程序码
    GetWxcodeAsync: async ({ commit, state }) => {
      if (state.wxcode) return;
      const rs = await https.cloud({
        action: "getWXacodeUnlimited",
      });
      console.log(rs);
      if (rs.code !== 0) return;
      let base64 = arrayBufferToBase64(rs.data.buffer.data);
      base64 = "data:" + rs.data.contentType + ";base64," + base64;
      commit("setWxcode", base64);
    },
  },
  modules: {
    auth,
  },
});
