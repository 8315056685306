// app相关配置
export const isDev = process.env.NODE_ENV === "development";

export const ENVPATH = process.env.VUE_APP_ENVPATH; // 环境变量

const SOCKET_URL = `wss://cloudapi.b2co.cn/${
  ENVPATH ? ENVPATH + "/" : ""
}websocket`; // socket 地址

const LOGIN = `https://cloud.b2co.cn/${ENVPATH}`; // 登录页地址

export const MINI_APP_ID = "wx91a5770dab51e4ae"; // 小程序appid

export default {
  SOCKET_URL,
  LOGIN,
  ENVPATH,
  MINI_APP_ID,
};
